<template>
  <div class="px-4 sm:px-0">
    <div
      class="container mx-auto flex h-full flex-col items-center justify-center py-16 sm:py-0"
    >
      <transition
        enter-active-class="miniFadeInTop"
        leave-active-class="miniFadeOutBottom"
        mode="out-in"
      >
        <div v-if="!isSuccess" key="form">
          <form @submit.prevent="sendLink" novalidate="true">
            <div class="mb-4 text-2xl font-semibold">Forgotten password</div>
            <div class="mb-4 w-full">
              Don’t worry! Provide your email below and we’ll send you a link to
              help you reset your password.
            </div>
            <div
              v-if="hasError"
              class="error-msg rounded border-2 border-red-400 bg-red-100 p-4 text-red-400"
            >
              There was an error processing your request. Please try again later
            </div>
            <div class="input-group mt-8 w-full">
              <input
                v-model.trim="email"
                required
                ref="email"
                type="text"
                placeholder="example@example.com"
                class="w-full"
              />
            </div>
            <loading-button
              :is-loading="isLoading"
              :is-enabled="formValid"
              :is-full="true"
              label="Send email"
            />
          </form>
          <div class="mt-8 text-center">
            <router-link
              class="text-sm text-gray-500 hover:underline"
              :to="{ name: 'login' }"
            >
              <font-awesome-icon
                :icon="['far', 'arrow-left-long']"
                class="mr-2"
              />back to login</router-link
            >
          </div>
        </div>
        <div v-else key="success" class="w-full text-center sm:w-2/5">
          <div class="mb-8 font-merri text-2xl">Thanks!</div>
          <div class="mb-8">
            If an account exists, an email will be sent to {{ this.email }} with
            further instructions on how to reset your password.
          </div>
          <div>
            If you don't receive an email contact us at
            <a
              class="text-teal-400 hover:underline"
              href="mailto:team@thecoolcatclub.com"
              >team@thecoolcatclub.com</a
            >
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import LoadingButton from "@/components/LoadingButton";

export default {
  metaInfo: {
    title: "Forgotten Password",
  },
  data() {
    return {
      email: "",
      hasError: false,
      isLoading: false,
      isSuccess: false,
    };
  },
  components: {
    LoadingButton,
  },
  computed: {
    formValid() {
      if (this.email) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    sendLink: function () {
      this.isLoading = true;
      this.isSuccess = true;
      http
        .post("/me/password/reset", { email: this.email })
        .then(() => {
          this.isLoading = false;
          this.isSuccess = true;

          this.$store.dispatch("sendMpEvent", {
            event: "Forgotten Password - Email",
            properties: {
              location: this.$route.name,
              type: "EVT",
            },
          });
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          throw Error(error.response.data.error.message);
        });
    },
  },
  mounted() {
    this.$refs.email.focus();
  },
};
</script>

<style lang="scss" scoped>
form {
  max-width: 360px;
  width: 100%;
}
</style>
